import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content", "input"];

  toggle(event) {
    const isChecked = event.target.checked;
    this.contentTarget.classList.toggle("hidden", !isChecked);
  }
}
