import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { mask: String };

  connect() {
    this.handleInput();
    this.inputHandler = this.handleInput.bind(this);
    this.element.addEventListener("input", this.inputHandler);
  }

  disconnect() {
    this.element.removeEventListener("input", this.inputHandler);
  }

  handleInput(_event) {
    this.element.value = this.applyMask(this.element.value, this.maskValue);
  }

  applyMask(value, mask) {
    if (!mask) return value;

    let result = "";
    let numbers = value.match(/\d/g) || [];
    let valueIndex = 0;

    for (let i = 0; i < mask.length; i++) {
      if (numbers.length == 0 && valueIndex >= value.length) break;

      if (mask[i] === "9") {
        if (/\d/.test(value[valueIndex])) {
          result += value[valueIndex];
          numbers = numbers.slice(1);
        } else {
          i--;
        }
        valueIndex++;
      } else {
        if (value[valueIndex] === mask[i]) {
          result += mask[i];
          valueIndex++;
        } else {
          result += mask[i];
        }
      }
    }

    return result;
  }
}
