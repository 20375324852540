import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  confirmSubmit() {
    const form = document.querySelector("#appointment_action_form");
    form.querySelector('[name="appointment[ignore_overlap]"]').value = true;
    form.querySelector('[name="appointment[ignore_retroactive]"]').value = true;
    const submitButton = this.element.querySelector(
      "button#confirmation_submit"
    );
    submitButton.disabled = true;
    form.requestSubmit();
  }
}
