import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["dialog", "content", "trigger"];
  static values = { openAutomatically: String };

  initialize() {
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
    this.observeContentChanges = this.observeContentChanges.bind(this);
  }

  connect() {
    if (!this.hasDialogTarget) return;

    // TODO: Refactor to work with stimulus values from data attributes that trigger on state change more reliably
    this.handleStateChange();

    if (!this.hasContentTarget) return;

    this.contentObserver = new MutationObserver(this.observeContentChanges);
    this.contentObserver.observe(this.contentTarget, {
      childList: true,
      subtree: true,
    });
  }

  disconnect() {
    if (!this.hasDialogTarget) return;
    this.close();

    if (this.contentObserver) {
      this.contentObserver.disconnect();
    }
  }

  handleStateChange() {
    const state = this.dialogTarget.dataset.state;
    if (state === "opened") {
      this.open();
    } else if (state === "closed") {
      this.close();
    }
  }

  open() {
    const openedDialogContent = document.querySelector(
      '[data-state="opened"] [data-dialog-target="content"]'
    );
    this.dialogTarget.dataset.state = "opened";
    this.dialogTarget.setAttribute("aria-hidden", "false");
    document.addEventListener("keydown", this.handleKeyDown);
    this.focusableElements = this.dialogTarget.querySelectorAll(
      "button, [href], input, select, textarea, [tabindex]:not([tabindex='-1'])"
    );
    this.firstFocusableElement = this.focusableElements[0];
    this.lastFocusableElement =
      this.focusableElements[this.focusableElements.length - 1];
    this.firstFocusableElement.focus();

    if (window.innerWidth < 768 && openedDialogContent) {
      setTimeout(() => {
        openedDialogContent.scrollTop = 0;
      }, 10);
    }
  }

  close() {
    this.dialogTarget.dataset.state = "closed";
    this.dialogTarget.setAttribute("aria-hidden", "true");
    document.removeEventListener("keydown", this.handleKeyDown);
    document.removeEventListener("focusin", this.handleFocusTrap);
    if (this.hasTriggerTarget) this.triggerTarget.focus();
  }

  closeOnClick(event) {
    event.preventDefault();
    this.close();
  }

  handleKeyDown(event) {
    if (event.key === "Escape") {
      const dialogs = document.querySelectorAll(
        '[data-dialog-target="dialog"]'
      );
      const topDialog = Array.from(dialogs)
        .reverse()
        .find((dialog) => dialog.dataset.state === "opened");
      if (topDialog === this.dialogTarget) {
        this.close();
      }
    }

    if (event.key !== "Tab") return;

    if (this.focusableElements.length === 0) {
      event.preventDefault();
      return;
    }

    if (event.shiftKey) {
      if (document.activeElement !== this.firstFocusableElement) return;

      event.preventDefault();
      this.lastFocusableElement.focus();
    } else {
      if (document.activeElement !== this.lastFocusableElement) return;

      event.preventDefault();
      this.firstFocusableElement.focus();
    }
  }

  handleBackdropClick(event) {
    if (event.target !== this.dialogTarget) return;

    this.close();
  }

  observeContentChanges(mutations) {
    mutations.forEach((_mutation) => {
      if (
        !this.openAutomaticallyValue ||
        this.dialogTarget.dataset.state === "opened"
      )
        return;

      const hasNonEmptyChild = Array.from(
        this.contentTarget.querySelectorAll("*")
      ).some((child) => child.textContent.trim() !== "");
      if (!hasNonEmptyChild) return;

      this.open();
    });
  }
}
