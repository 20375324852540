import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.boundSetAsNotBusy = this.setAsNotBusy.bind(this);
    this.element.addEventListener("click", this.setAsBusy.bind(this));
  }

  disconnect() {
    document.removeEventListener("turbo:frame-render", this.boundSetAsNotBusy);
  }

  setAsBusy() {
    this.element.setAttribute("aria-busy", "true");
    document.addEventListener("turbo:frame-render", this.boundSetAsNotBusy);
  }

  setAsNotBusy() {
    this.element.setAttribute("aria-busy", "false");
    document.removeEventListener("turbo:frame-render", this.boundSetAsNotBusy);
  }
}
