import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["link"];

  connect() {
    this.element.addEventListener("click", (event) => {
      const link = event.target.closest("a");
      if (!link) return;

      const targetId = link.getAttribute("href").split("#")[1];
      if (!targetId) return;

      const targetElement = document.getElementById(targetId);
      if (!targetElement) return;

      targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
    });
  }
}
