import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    if (this.element.dataset.errors !== "true") return;

    this.scrollToErrors();
  }

  scrollToErrors() {
    const firstError = this.element.querySelector(".field_with_errors");
    if (firstError) {
      firstError.scrollIntoView({ behavior: "smooth", block: "start" });
      firstError.closest(".form-group").querySelector("input").focus();
    } else {
      this.element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }
}
