import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { permanent: Boolean };

  connect() {
    if (this.element.textContent.trim() === "") return;

    if (this.permanentValue) return;
    setTimeout(() => this.close(), 3000);
  }

  close() {
    this.element.setAttribute("data-state", "closed");
    setTimeout(() => {
      this.element.remove();
    }, 1000);
  }
}
