import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["menu"];

  showMenu = false;

  connect() {
    this.bindedClose = this.close.bind(this);
  }

  disconnect() {
    document.removeEventListener("turbo:before-cache", this.bindedClose);
  }

  open() {
    this.showMenu = true;
    this.element.dataset.state = "opened";

    document.body.classList.add("overflow-hidden");
    document.addEventListener("turbo:before-cache", this.bindedClose);
  }

  close() {
    this.showMenu = false;
    this.element.dataset.state = "closed";

    document.body.classList.remove("overflow-hidden");
    document.removeEventListener("turbo:before-cache", this.bindedClose);
  }

  toggle() {
    this.showMenu ? this.close() : this.open();
  }
}
