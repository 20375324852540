import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["message"];

  connect() {
    this.checkStatus();
    this.timeout = setTimeout(() => this.showError(), 30000);
  }

  disconnect() {
    clearTimeout(this.timeout);
    clearInterval(this.interval);
  }

  checkStatus() {
    this.interval = setInterval(async () => {
      const response = await fetch("/assinatura/sucesso/status");
      const data = await response.json();
      if (data.status !== "active") return;

      clearInterval(this.interval);
      clearTimeout(this.timeout);
      this.element.classList.add("hidden");
      document.querySelector("#success-message").classList.remove("hidden");
    }, 1000);
  }

  showError() {
    // TODO: [NOMU-267] Report to Sentry
    clearInterval(this.interval);
    this.element.querySelector("svg").remove(); // Remove loader
    this.messageTarget.textContent =
      "Desculpe, não foi possível configurar a assinatura automaticamente. Por favor entre em contato via contato@nomu.com.br";
  }
}
