import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const lastShown = localStorage.getItem("trialToastLastShown");
    const today = new Date().toLocaleDateString();
    if (lastShown === today) return;

    this.showNotification();
    localStorage.setItem("trialToastLastShown", today);
  }

  showNotification() {
    this.element.classList.remove("hidden");
  }
}
