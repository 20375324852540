import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["counter"];
  static values = { seconds: Number, sentEmailAt: Number };

  connect() {
    const sentAt = this.sentEmailAtValue;
    const elapsed = Math.floor(Date.now() / 1000 - sentAt);
    const remaining = Math.max(60 - elapsed, 0);

    if (remaining <= 0) {
      this.enableButton();
    } else {
      this.endTime = Date.now() + remaining * 1000;
      this.updateCounter();
    }
  }

  updateCounter() {
    const secondsLeft = Math.round((this.endTime - Date.now()) / 1000);
    this.counterTarget.textContent = `(${secondsLeft})`;

    if (secondsLeft <= 0) {
      this.enableButton();
    } else {
      setTimeout(() => this.updateCounter(), 1000);
    }
  }

  enableButton() {
    this.element.removeAttribute("disabled");
    this.counterTarget.textContent = "";
  }

  async resend() {
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get("email");
    this.element.disabled = true;
    this.element.classList.add("hover:!no-underline");
    this.element.textContent = "Reenviando e-mail...";

    fetch(
      `/login/email/confirmacao/resend_email?email=${encodeURIComponent(
        email
      )}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute("content"),
        },
      }
    ).then((response) => {
      if (!response.ok) return;

      this.element.textContent = "E-mail reenviado!";
    });
  }
}
