import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkbox"];

  update(event) {
    const checked = event.target.checked;
    event.target.closest("div").classList.add("animate-fast-pulse");
    fetch("/notificacoes-automaticas", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
        Accept: "text/vnd.turbo-stream.html",
      },
      body: JSON.stringify({ enable_notifications_by_default: checked }),
    })
      .then((r) => r.text())
      .then((html) => Turbo.renderStreamMessage(html))
      .finally(() => {
        event.target.closest("div").classList.remove("animate-fast-pulse");
      });
  }
}
