import "@hotwired/turbo-rails";
import "../controllers";
import "../channels";
import "../action_cable";
import { init as SentryInit } from "@sentry/browser";

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.register("/service_worker.js");
}

SentryInit({
  dsn: window._sentry_dsn,
  environment: window._env,
  integrations: [],
  tracesSampleRate: 0,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
});

document.addEventListener("turbo:visit", () => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  document.cookie = `time_zone=${timeZone}; path=/`;
});

document.addEventListener("turbo:before-fetch-request", (event) => {
  if (event.target.tagName !== "TURBO-FRAME") return;

  event.target.setAttribute("turbo-busy", "true");
});

document.addEventListener("turbo:before-frame-render", (event) => {
  if (event.target.tagName !== "TURBO-FRAME") return;

  event.target.removeAttribute("turbo-busy");
});
