import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  search(event) {
    const query = event.target.value;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      event.target.parentElement.setAttribute("aria-busy", "true");
      fetch(`/agenda/busca_cliente?query=${encodeURIComponent(query)}`, {
        headers: { Accept: "text/vnd.turbo-stream.html" },
      })
        .then((r) => r.text())
        .then((html) => Turbo.renderStreamMessage(html))
        .then(() =>
          event.target.parentElement.setAttribute("aria-busy", "false")
        );
    }, 300);
  }
}
