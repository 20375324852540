import { Controller } from "@hotwired/stimulus";
import * as Turbo from "@hotwired/turbo";

export default class extends Controller {
  connect() {
    this.frame = this.element.closest("turbo-frame");
    if (!this.shouldInterceptTurbo()) return;

    this.frameMissingHandler = this.frameMissing.bind(this);
    this.submitEndHandler = this.submitEnd.bind(this);

    this.element.addEventListener("turbo:submit-end", this.submitEndHandler);
    this.frame.addEventListener(
      "turbo:frame-missing",
      this.frameMissingHandler
    );
  }

  disconnect() {
    if (!this.shouldInterceptTurbo()) return;

    this.element.removeEventListener("turbo:submit-end", this.submitEndHandler);
    this.frame.removeEventListener(
      "turbo:frame-missing",
      this.frameMissingHandler
    );
  }

  frameMissing(event) {
    if (!event.detail.response.redirected) return;

    event.preventDefault();
    const action = this.frame.getAttribute("action");
    Turbo.visit(event.detail.response.url, {
      action: action || "advance",
    });
  }

  submitEnd() {
    this.element
      .querySelectorAll('button, input[type="submit"]')
      .forEach((element) => (element.disabled = true));
  }

  shouldInterceptTurbo() {
    return this.frame && this.element.dataset.turboFrame !== "_top";
  }
}
