import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.today = new Date().toISOString().split("T")[0];
    this.date = this.element.value || this.today;
  }

  change(event) {
    const selectedDate = event.target.value;
    if (selectedDate === this.date) return;

    this.element.blur();

    const url = `/agenda/calendar?start_date=${selectedDate}`;
    Turbo.visit(url, { frame: "calendar" });
  }
}
