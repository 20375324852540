import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "zipCode",
    "street",
    "neighborhood",
    "city",
    "state",
    "number",
  ];

  // TODO: Add frontend Sentry error reporting
  async lookup(event) {
    const zipCode = event.target.value.replace(/\D/g, "");
    if (zipCode.length !== 8) return;

    this.disableFields();
    try {
      const address = await this.fetchViaCep(zipCode);
      this.handleAddress(address);
    } catch (error) {
      try {
        const address = await this.fetchApiCep(zipCode);
        this.handleAddress(address);
      } catch (error) {
        this.clearAddress();
        this.streetTarget.focus();
      }
    } finally {
      this.enableFields();
    }
  }

  handleAddress(address) {
    this.fillAddress(address);
    this.numberTarget.focus();
  }

  async fetchViaCep(zipCode) {
    const response = await fetch(`https://viacep.com.br/ws/${zipCode}/json/`);
    if (!response.ok) throw new Error("ViaCEP failed");
    const data = await response.json();
    return {
      street: data.logradouro,
      neighborhood: data.bairro,
      city: data.localidade,
      state: data.uf,
    };
  }

  async fetchApiCep(zipCode) {
    const response = await fetch(
      `https://brasilapi.com.br/api/cep/v1/${zipCode}`
    );
    if (!response.ok) throw new Error("BrasilAPI failed");
    const data = await response.json();
    return {
      street: data.street,
      neighborhood: data.neighborhood,
      city: data.city,
      state: data.state,
    };
  }

  fillAddress(address) {
    this.streetTarget.value = address.street;
    this.neighborhoodTarget.value = address.neighborhood;
    this.cityTarget.value = address.city;
    this.stateTarget.value = address.state;
  }

  disableFields() {
    this.streetTarget.disabled = true;
    this.neighborhoodTarget.disabled = true;
    this.cityTarget.disabled = true;
    this.stateTarget.disabled = true;
  }

  enableFields() {
    this.streetTarget.disabled = false;
    this.neighborhoodTarget.disabled = false;
    this.cityTarget.disabled = false;
    this.stateTarget.disabled = false;
  }

  clearAddress() {
    this.streetTarget.value = "";
    this.neighborhoodTarget.value = "";
    this.cityTarget.value = "";
    this.stateTarget.value = "";
  }
}
