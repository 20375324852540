import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.element.addEventListener(
      "turbo:frame-render",
      this.scrollToAppointments.bind(this)
    );
  }

  disconnect() {
    this.element.removeEventListener(
      "turbo:frame-render",
      this.scrollToAppointments.bind(this)
    );
  }

  scrollToAppointments() {
    if (!this.isMobile()) return;

    const target = document.querySelector("#customer-appointments-anchor");
    if (target) {
      target.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }

  // TODO: Extract to some utility file?
  isMobile() {
    return window.innerWidth < 768;
  }
}
