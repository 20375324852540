import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    if (document.body.classList.contains("native")) return;

    const url = new URL(window.location);
    if (!url.searchParams.has("appointment_id")) return;

    const appointmentId = url.searchParams.get("appointment_id");
    const appointmentElement = document.querySelector(
      `#appointment-${appointmentId}`
    );
    if (!appointmentElement) return;

    setTimeout(() => {
      appointmentElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }, 1);
  }
}
